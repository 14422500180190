<template>
  <PageWithLayout
    :isBodyMiddle="true">
    <div class="img_profile">
      <img
        :src="getUserData.image"
        class="img_g"
        alt="profile"
        onerror="this.src='/assets/images/profile_default.png'">
    </div>
    <div class="area_user_info">
      <div class="inner_user_info">
        <dl>
          <dt class="screen_out">권한</dt>
          <dd>
            <Badge
              :text="getUserData.role | convertIdToText('member_role')"
              :badgeStyle="getUserData.role | convertIdToColor('member_role')"
              badgeSize="small"
              :badgeFill="false" />
          </dd>
        </dl>
        <dl>
          <dt>이름</dt>
          <dd>{{ getUserData.name || '-' }}</dd>
        </dl>
        <dl>
          <dt>이메일</dt>
          <dd>{{ getUserData.email }}</dd>
        </dl>
        <dl>
          <dt>마지막 로그인 일시</dt>
          <dd>{{ getUserData.lastLoginAt | convertDateFormat}}</dd>
        </dl>
        <div class="area_btn">
          <Button
            btnSize="small"
            btnStyle="secondary_border"
            text="비밀번호 변경"
            @onClickBtn="newPasswordViewModel.onClickPasswordChangePopup()"/>
          <Button
            btnSize="small"
            btnStyle="secondary_border"
            text="MFA 설정"
            @onClickBtn="myViewModel.onClickMfaQrcodePopup()"/>
        </div>
      </div>
    </div>
    <template v-slot:popup>
      <PasswordChangePopup
        v-if="newPasswordViewModel.isPasswordChangePopup"
        :viewModel="newPasswordViewModel"
        title="비밀번호 변경"
        closeBtnText="취소"
        compeleteBtnText="변경"
        @onClickClose="newPasswordViewModel.onClickClosePasswordChangePopup()"
        @onClickComplete="newPasswordViewModel.onClickCompletePasswordChangePopup()"/>
      <MfaQrcodePopup
        v-if="myViewModel.isMfaQrcodePopup"
        :userEmail="getUserData.email"
        :mfaCode="myViewModel.mfaCode"
        @onClickClose="myViewModel.onClickCloseMfaQrcodePopup()"/>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Badge from '@lemontree-ai/lemontree-admin-common-front/components/common/badge/Badge';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
//popup
import PasswordChangePopup from '@/views/my/view/popup/PasswordChangePopup'
import MfaQrcodePopup from '@/views/my/view/popup/MfaQrcodePopup'
// viewModel
import NewPasswordViewModel from '@/views/my/viewModel/NewPasswordViewModel'
import MyViewModel from '@/views/my/viewModel/MyViewModel'

import { mapGetters } from 'vuex';

export default {
  name:'My',
  components:{
    PageWithLayout,
    Badge,
    Button,
    PasswordChangePopup,
    MfaQrcodePopup
  },
  computed:{
    ...mapGetters('commonUserData', [ 'getUserData', ]),
  },
  // beforeMount(){
  //   this.myViewModel.init();
  // },
  data(){
    return{
      newPasswordViewModel: new NewPasswordViewModel(),
      myViewModel: new MyViewModel(),
    }
  },
}
</script>

<style scoped>
.img_profile{overflow:hidden;width:260px;margin:20px auto 0;border-radius:6px}
.area_user_info{margin-top:40px;text-align:center}
.inner_user_info{display:inline-block;min-width:260px;text-align:left;vertical-align:top}
.area_user_info dl + dl,
.area_user_info dl + .area_btn{margin-top:18px}
.area_user_info dl{font-weight:600;}
.area_user_info dt{font-size:14px;line-height:16px}
.area_user_info dd{margin-top:2px;font-size:16px}

@media (prefers-color-scheme: dark) {
  .area_user_info dd{color:#fff}
}
</style>