<template>
  <Popup
    title="MFA 설정"
    :maxWidth="500"
    closeBtnText="닫기"
    @onClickClose="$emit('onClickClose')">
    <template v-if="mfaCode">
      <p class="desc_info">authy 나 google authenticator 앱을 설치하고<br>아래 QR코드 스캔 또는 코드를 입력하여 MFA를 설정하세요<br>이 후 로그인부터 생성되는 MFA코드를 입력하여 로그인하시면 됩니다</p>
      <QrcodeVue
        :value="mfaQrCode"
        :size="160"
        background="#3DBF69"
        foreground="#ffffff"
        level="L"
        class="box_qrcode"/>
      <Input
        :value="mfaCode"
        :isDisabled="true"/>
    </template>
    <p v-else class="desc_mfa">MFA가 이미 설정되었습니다<br>관리자에게 문의해주세요</p>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import QrcodeVue from 'qrcode.vue'
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input'

export default {
  name:'MfaQrcodePopup',
  props:{
    mfaCode: String,
    userEmail: String,
  },
  components:{
    Popup,
    QrcodeVue,
    Input
  },
  computed:{
    mfaQrCode(){
      return `otpauth://totp/lemontreeAdmin:${this.userEmail}?secret=${this.mfaCode}`
    }
  }
}
</script>
<style scoped>
.desc_info{line-height:20px;}
.box_qrcode{width:240px;margin:30px auto 0;padding:40px 0;border-radius:20px;text-align:center;background-color:#3DBF69}
.tf_comm{display:block;width:240px;margin:20px auto 0}
.desc_mfa{font-size:16px;line-height:24px;color:#222}
</style>