import { POST_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';

export default class MyViewModel {
  constructor() {
    this.isMfaQrcodePopup = false;
    this.mfaCode = '';
  }
  onClickMfaQrcodePopup(){
    const requestMfa = () => {
      this.getMfaSetting();
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `이미 MFA설정이 되어있을 경우<br>기존의 MFA설정이 제거되고<br>새로운 MFA가 발급됩니다.`,
      closeBtnText: '취소',
      compeleteBtnText: '확인',
      confirmCallBack: requestMfa,
    });
  }
  onClickCloseMfaQrcodePopup(){
    this.isMfaQrcodePopup = false;
  }
  getMfaSetting(){
    const path = `${apiPath.MFA_SETTING}`;
    POST_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.mfaCode = resultData;
      this.isMfaQrcodePopup = true;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}